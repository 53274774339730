import React from 'react';
import { getFormatNumberWithCommas } from '../../modules/timeline/components/EnterButton/utils';
import { AppContext } from '../../apollo/wrap-root-element';
import { navigate } from 'gatsby';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';

const DonationProjectCard = ({
  shortDescriptions,
  raisedMoney,
  raisedPercentage,
  goal,
  title,
  projectId,
  image,
  uid,
}) => {
  const { donatingProjectId, setDonatingProjectId } = React.useContext(AppContext);

  return (
    <div
      className="cols"
      onClick={() => {
        setDonatingProjectId(projectId);
        navigate(`/donate/${uid}`);
      }}
      style={{ cursor: 'pointer' }}
    >
      <div className="project-donation-card-item bg-white">
        <div>
          <div className="scale-on-hover">
            <img width={'100%'} height={'100%'} src={image} alt={title} />
          </div>
          <div className="project-donation-card-item-body">
            <div>
              <p className="title">{title}</p>
              <div className="progress-track">
                <div className="progress-thumb" style={{ width: raisedPercentage }}></div>
              </div>
              <div className="goal-container">
                <p>
                  <b>
                    <CurrencySwitcher price={+raisedMoney} isCommaSeparated={true} />
                  </b>
                  <span className="goals-raised-text">Raised</span>
                </p>
                <p>
                  <b>
                    <CurrencySwitcher price={+goal} isCommaSeparated={true} />
                  </b>
                  <span className="goals-raised-text">Goal</span>
                </p>
              </div>
              <p className="descriptions">{shortDescriptions}</p>
            </div>

            <div className="btn-group-donate">
              <button
                onClick={() => {
                  setDonatingProjectId(projectId);
                  navigate(`/donate/${uid}`);
                }}
                className="btn btn-md btn-primary-ii  give-button"
              >
                Give
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationProjectCard;
