import React from 'react';
import { getFormatNumberWithCommas } from '../../modules/timeline/components/EnterButton/utils';
import { useFetch } from '../../hooks';
import DonationProjectCard from './DonationProjectCard';
import { Link, navigate } from 'gatsby';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';

const ProjectsLists = ({ featuredProjects, otherProjects, languageProjectTotalGoal }) => {
  const [getProjectRaisedMoney, raisedMoneyData, error, loading] = useFetch();

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      getProjectRaisedMoney('api/donate/total-donations-by-parent-project', {
        onSuccess: () => {},
        onError: () => {},
      });
    }
  }, []);

  const getPercentageOfRaisedMoney = (projectName, fundsRaised, totalAmount) => {
    if (projectName !== undefined) {
      const raisedMoneyForProject = getRaisedMoneyForProject(projectName, fundsRaised);
      const percentage = (raisedMoneyForProject / totalAmount) * 100;

      return Math.ceil(percentage) + '%';
    }
    return 0;
  };

  const getRaisedMoneyForProject = (projectName, fundsRaised) => {
    if (projectName !== undefined) {
      const findProject = raisedMoneyData?.data?.find((item) => item?._id?.split('-').join(' ') === projectName);

      let raisedMoney = findProject?.totalAmount ? findProject.totalAmount : 0;
      raisedMoney = raisedMoney + (fundsRaised ? fundsRaised : 0);
      return raisedMoney;
    }
    return 0;
  };

  return (
    <>
      <div className="wrapper wrapper-lg ">
        <div className="projects-donations pd-x-md">
          <div className="projects-donations-container">
            {featuredProjects?.map((item) => (
              <div
                className="project-card"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/language-donations-page');
                }}
              >
                {console.log(item, 'item')}
                <div className="scale-on-hover">
                  <img className="thumbnail-image" src={item.thumbnail.url} alt={item.title.text} />
                </div>
                <div>
                  <div className="project-card-detail">
                    <h3 className="project-name-title">{item.title.text}</h3>

                    <div className="progress-track">
                      <div
                        className="progress-thumb"
                        style={{
                          width: getPercentageOfRaisedMoney(
                            item.detailsPage.id ? item.detailsPage.id : item.title.text,
                            item.fundsRaised,
                            item?.detailsPage?.slug === 'language-donations-page'
                              ? languageProjectTotalGoal
                              : +item.goal
                          ),
                        }}
                      ></div>
                    </div>

                    <div className="goal-container">
                      <p>
                        <b>
                          <CurrencySwitcher
                            price={getRaisedMoneyForProject(
                              item.detailsPage.id ? item.detailsPage.id : item.title.text,
                              item.fundsRaised
                            )}
                            isCommaSeparated={true}
                          />
                        </b>
                        <span className="goals-raised-text">Raised</span>
                      </p>
                      <p>
                        <b>
                          <CurrencySwitcher
                            price={
                              item?.detailsPage?.slug === 'language-donations-page'
                                ? languageProjectTotalGoal
                                : +item.goal
                            }
                            isCommaSeparated={true}
                          />
                        </b>
                        <span className="goals-raised-text">Goal</span>
                      </p>
                    </div>
                    <p className="descriptions ">{item.shortDescriptions.text}</p>
                    <div className="btn-group">
                      {/* <a
                        href={`${item.detailsPage.slug ? '/language-donations-page' : ''}`}
                        className="btn btn-md btn-outline btn-tour-jumbotron give-btn">
                        Read More
                      </a> */}
                      <Link
                        to={`${'/language-donations-page'}`}
                        className="btn  btn-primary-ii btn-tour-jumbotron give-btn"
                      >
                        Give
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="  cat_watch-list">
            <div
              className="d-flex columns-3 project-donation-cards-lists"
              style={{ alignItems: 'stretch', padding: '0 10px' }}
            >
              {otherProjects.map((item) => {
                return (
                  <>
                    <DonationProjectCard
                      raisedMoney={getRaisedMoneyForProject(
                        item.detailsPage.id ? item.detailsPage.id : item.title.text,
                        item.fundsRaised
                      )}
                      raisedPercentage={getPercentageOfRaisedMoney(
                        item.detailsPage.id ? item.detailsPage.id : item.title.text,
                        item.fundsRaised,
                        +item.goal
                      )}
                      languageProjectId={item.title.text}
                      shortDescriptions={item.shortDescriptions.text}
                      goal={+item.goal}
                      title={item.title.text}
                      projectId={item.title.text}
                      image={item.thumbnail.url}
                      uid={item.detailsPage.uid}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>{' '}
    </>
  );
};

export default ProjectsLists;
